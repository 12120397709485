import { PageOptions, axiosInstance } from '@mgk-eld/utils';

interface GetHistoryParams extends PageOptions {
  company_id: number;
}

const ep = `subscriptions`;

const subscriptionInfo = (company_id: number) =>
  axiosInstance.get(`${ep}/${company_id}`);

const getHistory = ({ company_id, ...params }: GetHistoryParams) =>
  axiosInstance.get(`${ep}/${company_id}/history`, {
    params,
  });

const getPrice = (quantity: number) =>
  axiosInstance.get(`tariffs/price`, { params: { quantity } });

const subscribe = (company_id: number, vehicles_quantity: number) =>
  axiosInstance.post(`${ep}`, { vehicles_quantity, company_id });

const updateSubscription = (company_id: number, vehicles_quantity: number) =>
  axiosInstance.put(`${ep}/${company_id}`, { vehicles_quantity });

const updateMethod = (company_id: number) =>
  axiosInstance.get(`payments/${company_id}/payment-method/change`);

export const paymentsApi = {
  getHistory,
  getPrice,
  subscribe,
  updateMethod,
  subscriptionInfo,
  updateSubscription,
};
