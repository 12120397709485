import { isStage } from '../constants';
import { Reseller } from '../types';
import { configApi } from './appConfigApi';

export const APP_CONFIG = 'appConfig';

export type AppConfig = Partial<Reseller>;

export const getAppConfigFromStorage = () => {
  const config = localStorage.getItem(APP_CONFIG);
  if (config) return JSON.parse(config) as AppConfig;
  return null;
};

export const getAppConfig = async (): Promise<AppConfig> => {
  let config = localStorage.getItem(APP_CONFIG);
  if (!config) {
    try {
      const { data } = await configApi.getAppConfig();
      config = JSON.stringify(data);
      // store rest in localstorage
      localStorage.setItem(APP_CONFIG, config);
    } catch (error) {
      console.error(error);
    }
  }

  const appConfig: AppConfig = JSON.parse(config as string);
  const domain = `https://${isStage ? 'stage.' : ''}api.${appConfig.domain}`;
  return { ...appConfig, domain };
};

export const changeAppFavion = (href: string) => {
  const link = document.createElement('link');
  link.rel = 'icon';
  link.href = href;
  document.head.appendChild(link);
};
